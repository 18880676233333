import React, {useState, useEffect} from 'react';
import Play from '../icons/Play.svg'
import PlayPrev from '../icons/PlayPrev.svg'
import PlayNext from '../icons/PlayNext.svg'
import Pause from '../icons/Pause.svg'
import Spotify from '../icons/Spotify.svg'
import ProgressSlider from './ProgressSlider'
import SoundSlider from './SoundSlider'
import Default from '../covers/Default.jpg'
import ShareContent from '../ShareContent'
import Slider from 'react-slick'
import '../MainPage/animation.css'

const OverLay = (props) => {

    const [mouse, setMouse] = useState(false)
    const [slideHeight, setSlideHeight] = useState()
    const [fullScreenSliderRef, setFullScreenSliderRef] = useState()
    const [slidesToShow, setSlidesToShow] = useState()

    let mouseMove = (e) => {
        e.preventDefault()
        props.setMenuVisible("visible")
        setMouse(true)
        setTimeout(() => {
            setMouse(false); 
        }, 1000)
    }

    useEffect(()=>{
        if(!mouse){
            const identifier = setTimeout(() => {
                props.setMenuVisible("hidden")
            }, 1000);

            return () => {
                clearTimeout(identifier)
            }
        }
    },[mouse])

    
    const activeVideoClickHandler = () =>{
        if(props.playing){
            props.setMenuVisible('visible')
        }
        props.setPlaying(!props.playing)
    }

    const itemClickHandler = (item, index) => {
        props.setActiveVideo(item)
        localStorage.setItem('kw', props.plYoutubeKeyword)
        localStorage.setItem("video_index", index+1)
    }

    const spotifyClickHandler = () => {
        if (props.full){
            props.toggleFullScreen()
        }
        const identifier = setTimeout(() => {
            props.setTrigger(false)
            props.setScrolltoSp(1)
        }, 1000);
        return () => {
            clearTimeout(identifier)
        }
    }

      useEffect(()=>{
        if(props.dimentions.width < 600){
            setSlidesToShow(4)
            setSlideHeight(20) 
        } else if (props.dimentions.width < 1024){
            setSlidesToShow(5)
            setSlideHeight(12) 
        } else {
            setSlideHeight(7.031) 
            setSlidesToShow(8)
        }
    },[props.dimentions.width])


    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 3,
        rows: 1,
        inisialSlide: 0,
        mobileFirst:true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 4,
                initialSlide: 0,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 3,
                initialSlide: 0, 
                infinite: false,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 3, 
                initialSlide: 0,
                infinite: false,
              }
            }
          ]
      };


    return (
        <div className = "w-100" 
            onMouseMoveCapture={mouseMove}
            style = {{zIndex:"1", position: "absolute",  backgroundColor:"transparent", height: props.full ? "100vh": `${props.windowHeight}vw`,}}>
            <div className = "w-100 h-100 d-flex justify-content-center align-items-center">
                {
                    props.playing ?                
                    <div className = "d-flex flex-row align-items-center" 
                        style = {{
                            visibility: props.playing ? `${props.menuVisible}`:"visible",
                        }}
                    >                      
                        <img src = {PlayPrev} 
                            className = "m-3" 
                            style = {{
                                width:"2.8vw", 
                                height:"2.8vw", 
                                cursor:"pointer"
                            }} 
                            onClick = {(e)=>{e.stopPropagation(); props.prevHandler()}}/>
                        <img src = {Pause} 
                            style = {{
                                width:"3.906vw", 
                                height:"3.906vw", 
                                cursor:"pointer"
                            }} 
                            onClick = {(e)=>{e.stopPropagation(); activeVideoClickHandler()}}/>
                        <img src = {PlayNext} 
                            className = "m-3" 
                            style = {{
                                width:"2.8vw", 
                                height:"2.8vw", 
                                cursor:"pointer"
                            }} 
                            onClick = {()=>{props.nextHandler()}}/>
                    </div> : 
                    <div>
                        <img src = {Play} 
                        style = {{
                            width:"3.906vw", 
                            height:"3.906vw", 
                            visibility:"visible"
                        }} 
                        onClick = {(e)=>{e.stopPropagation(); activeVideoClickHandler()}} />
                    </div>
                }
            </div>
            <div className = "w-100 d-flex flex-column justify-content-between"
                style = {{
                        position:"absolute", 
                        left:"1.5%", 
                        right:"1.5%", 
                        zIndex:"1", 
                        bottom: "2%",
                    }}
                >
                {
                    props.full ? 
                    <div className = "col-12">
                        <div className = {!props.playing || props.menuVisible==="visible" ? "px-lg-5 visible" : "px-lg-5 hidden"}>
                            <Slider {...settings} ref={setFullScreenSliderRef} slickGoTo>
                                { props.playlist && props.playlist.length ?
                                    props.playlist.map((item, index) => (
                                        <div className='px-2' key={index} onClick = {()=>{itemClickHandler(item, index)}}>
                                            <div style = {{cursor:"pointer"}} >
                                                <img src = {item && item.snippet && item.snippet.thumbnails && item.snippet.thumbnails.standard && item.snippet.thumbnails.standard.url ? item.snippet.thumbnails.standard.url :"aaaaa"}
                                                    alt = "aaaaaa" 
                                                    style = {{
                                                        objectFit: 'cover',
                                                        width:"99%", 
                                                        height: `${slideHeight}vw`
                                                    }}
                                                    onError = {({ currentTarget }) => {
                                                        currentTarget.onerror = null // prevents looping
                                                        currentTarget.src=Default
                                                    }}    
                                                />

                                                <div className = "text-secondary fw-light py-3 pe-2 mb-3"
                                                    title={item.snippet.title}
                                                    style = {{
                                                        fontSize: `${props.fontSize}rem`,
                                                        height:`${props.fontSize*5.5}rem`,
                                                        maxHeight:`${props.fontSize*5.5}rem`, 
                                                        overflow:"hidden",
                                                        WebkitLineClamp: "3",
                                                        display: "-webkit-box",
                                                        WebkitBoxOrient: "vertical",
                                                    }}
                                                >{index+1+'. '+item.snippet.title}</div>
                                            </div>
                                        </div>
                                    )) : ''
                                }
                            </Slider>
                        </div>
                    </div> : ''
                }
                <div className = {props.full ? "w-100 pe-5" : "w-100 pe-3"}>
                    <ProgressSlider
                        menuVisible = {props.menuVisible}
                        played = {props.played}
                        setPlayed = {props.setPlayed}
                        playerRef = {props.playerRef}
                        playing = {props.playing}
                    />
                </div>
                <div className = "w-100 d-flex flex-row justify-content-between pe-3" 
                    style = {{paddingTop:"1%"}}>
                    <div className = 'w-100 d-flex flex-row justify-content-start align-items-center pe-3 mx-2'>
                        <img 
                            src={Spotify}
                            className = "mx-1" 
                            style = {{width:"1.5vw", height:"1.5vw"}}
                            onClick={spotifyClickHandler}
                        />
                        <SoundSlider
                            setMuted = {props.setMuted}
                            setSound = {props.setSound}
                            muted = {props.muted}
                            menuVisible = {props.menuVisible}
                            playing = {props.playing}
                        />
                        {
                            props.duration && props.timeDisplay &&
                            <div className = "text-white" 
                                style = {{
                                    visibility: props.playing ? `${props.menuVisible}`:"visible"
                                }}>{props.timeDisplay +'/' +props.duration}</div>
                        }
                    </div>
                    {
                        props.full && 
                        <div className = {!props.playing || props.menuVisible==="visible" ? 'mb-2 pe-5 visible' :'mb-2 pe-5 hidden' } >
                            <ShareContent 
                                dimentions = {props.dimentions}
                                index = {3}
                            />
                        </div>
                    }
                </div>
            </div>
        </div> 
    );
};

export default OverLay;