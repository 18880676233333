import React, {useState, useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import { Store } from 'react-notifications-component'
import Notification from './Notification'
import FacebookIcon from './icons/FacebookSocialIcon.svg'
import TwitterIcon from './icons/TwitterSocialIcon.svg'
import WhatsappIcon from './icons/WhatsappSocialIcon.svg'
import LinkedInIcon from './icons/LinkedInSocialIcon.svg'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import cuViewIcon from './icons/CuViewIcon.svg'
import Copy from './icons/Copy.png'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
  } from "react-share";


const ShareContent = (props) => {

    const [iconSize, setIconSize] = useState()
    const [searchParams, setSearchParams] = useSearchParams()
    const [copied, setCopied] = useState(false)

    let pl_youtube_keyword = searchParams.get('kw')
    let pl_Keyword_for_video_copy = localStorage.getItem('playlist_keyword')
    let spotify_id = searchParams.get('sp_id')
    let videoIndex = localStorage.getItem('video_index')
    let groupKeyword = searchParams.get('g')
    let pl_name = searchParams.get('pl')



    useEffect(()=>{
    },[])

    const redirectUrl = props.index === 1 ? `https://www.cumarket.net/ytb/v-${pl_youtube_keyword}` :
                        `https://www.cumarket.net/ytb/v-${pl_Keyword_for_video_copy}-${videoIndex}`

    const url = props.index === 1 ?
    `${process.env.REACT_APP_API_URL}/cuPlaylist/share/${groupKeyword}/${pl_name}/${pl_youtube_keyword}/${props.index}/`:
    props.index === 2 ?
    `${process.env.REACT_APP_API_URL}/cuPlaylist/share/${groupKeyword}/${pl_name}/${spotify_id}/${props.index}/`:
    `https://www.cumarket.net/ytb/v-${pl_Keyword_for_video_copy}-${videoIndex}`

    const urlForCopy = props.index === 1?
    encodeURI(`${process.env.REACT_APP_API_URL}/home?g=${groupKeyword}&kw=${pl_youtube_keyword}&pl=${pl_name}`):
    props.index === 2 ?
    encodeURI(`${process.env.REACT_APP_API_URL}/home?g=${groupKeyword}&sp_id=${spotify_id}`):
    `https://www.cumarket.net/ytb/v-${pl_Keyword_for_video_copy}-${videoIndex}`


    useEffect(()=>{
        if(props.dimentions.width < 600){
            setIconSize(3)
        } else if (props.dimentions.width < 1024){
            setIconSize(2)
        } else {
            setIconSize(1.45)
        }
    },[props.dimentions.width])


    const copyHandler = () => {
        Store.addNotification({
            content: <Notification message = {"Copied To Clipboard"}/>,
            type: 'custom',
            container: "bottom-center",
            insert: "top",
            dismiss: {
              duration: 2000,
              pauseOnHover: true
            }
          })
          setCopied(true)
    }

    return (
        <div className = "d-flex flex-row">
            {
                props.index !== 2 &&
                <div className='px-1'>
                    <a href = {redirectUrl} target="_blank">
                        <img src = {cuViewIcon} style = {{width:`${iconSize}vw`, cursor:"pointer"}}/>
                    </a>
                </div>
            }
            <div className = "px-1">
                <CopyToClipboard text={urlForCopy}
                    onCopy={() => setCopied(true)}>
                    <img src = {Copy} style = {{width:`${iconSize}vw`, cursor:"pointer"}} alt = "" onClick={() => copyHandler()}/>
                </CopyToClipboard>
            </div>
            <div className = "px-1">
                <FacebookShareButton url = {url}>
                    <img src = {FacebookIcon} style = {{width:`${iconSize}vw`}} alt = "" />
                </FacebookShareButton>
            </div>
            <div className = "px-1">
                <TwitterShareButton url = {url}>
                    <img src = {TwitterIcon} style = {{width:`${iconSize}vw`}} alt = "" />
                </TwitterShareButton>
            </div>
            {/* <div className = "px-1">
                <PinterestShareButton url = {url}>
                    <img src = {PinterestIcon} style = {{width:`${iconSize}vw`}} alt = "" />
                </PinterestShareButton>
            </div> */}
            <div className = "px-1">
                <WhatsappShareButton url = {url}>
                    <img src = {WhatsappIcon} style = {{width:`${iconSize}vw`}} alt = "" />
                </WhatsappShareButton>
            </div>
            <div className = "px-1">
                <LinkedinShareButton url = {url}>
                    <img src = {LinkedInIcon} style = {{width:`${iconSize}vw`}} alt = "" />
                </LinkedinShareButton>
            </div>
        </div>
    )
}

export default ShareContent
