import React, {useState} from 'react'
import MultiSlider, { Progress, Dot } from 'react-multi-bar-slider'
import SoundIcon from '../icons/SoundIcon.svg'
import '../MainPage/animation.css'

const SoundSlider = (props) => {

    const [vol, setVol] = useState(50)

    const soundControl = (newValue) => {
            props.setSound(newValue/100)
            setVol(newValue)
        }


    const handleMute = () => {
        props.setMuted(!props.muted)
    }

    return(
        <div className = {!props.playing || props.menuVisible === "visible" ? "d-flex flex-row p-1 align-items-center visible" 
        : "d-flex flex-row p-1 align-items-center hidden"}
            // style = {{visibility: props.playing ? `${props.menuVisible}`:"visible"}}
        >
            <img 
                className = "mx-3" 
                src = {SoundIcon} 
                style = {{width:"1.5vw", height:"1.5vw"}}
                onClick = {()=>{handleMute()}}
            />
            <MultiSlider 
                backgroundColor="#bbd8e7" 
                roundedCorners
                height={4}
                width = {80}
                onSlide = {soundControl}>
                {/* <Progress color="#07294c" /> */}
                <Progress color="#07294c" progress={vol} />
            </MultiSlider>
        </div>
    )
}

export default SoundSlider