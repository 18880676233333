import React, {useState} from 'react';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import './AuthStyles.css'
import axios from 'axios'

function ResetPasswordConfirm(props) {

  const [newPassword, setNewPassword] = useState('')
  const [reNewPassword, setReNewPassword] = useState('')
  const [infoMessage, setInfoMessage] = useState('')
  const [confirmMessage, setConfirmMessage] = useState('')
  const navigate = useNavigate()
  const {uid, token} = useParams()

  const body = {
    'uid': uid, 'token':token, 'new_password': newPassword, 're_new_password': reNewPassword
  }

  const submitHandler = () => {
    if (!newPassword || newPassword.length<8){
      setInfoMessage("Please, provide password at least 8 symbols")
    } 

    if(newPassword === reNewPassword){
      axios.post(`${process.env.REACT_APP_API_URL}/cuPlaylist/auth/users/reset_password_confirm/`, body)
      .then(response =>
        {
          setConfirmMessage('Password has been changed, go to sign in')
        }
      ).catch(message => {
          setInfoMessage(message.response && message.response.data.new_password
          ? message.response.data.new_password
          : "Something Went Wrong Please Try Again")
        })
    } else {
        setInfoMessage('Passwords do not match')
    }

  }

  return (
    <div className = "container-fluid min-vh-100 d-flex flex-column align-items-center justify-content-center"
    style={{background:"linear-gradient(#051932, #07294C)", padding:0, margin:0, height:"100vh"}}>
      <div className='container d-flex flex-column align-items-center justify-content-center'
        style={{gap:"1.5rem"}}>
        {
          confirmMessage && confirmMessage.length ?
          <>
            <div className = "text-info p-3 fs-4 text-center">{confirmMessage}</div>
              <button className = "col-10 col-md-8 col-lg-4 text-primary bg-info fw-bold border-0 rounded py-2"
              onClick={()=>navigate('/login')}
            >Sign In</button>
          </>
          :
          <>
            <div className = "text-info fs-2 fw-light">Reset Password</div>
            {infoMessage && <div className = "col-10 col-md-8 col-lg-4 text-info text-center">{infoMessage}</div>}
            <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input"
              type="password"
              value = {newPassword}
              placeholder="password"
              name = "forgetPassword"
              onChange = {(e) => setNewPassword(e.target.value)} />

            <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input"
              type="password"
              value = {reNewPassword}
              placeholder="confirm password"
              name = "forgetPasswordConfirm"
              onChange = {(e) => setReNewPassword(e.target.value)} />

            <button className = "col-10 col-md-8 col-lg-4 text-primary bg-info fw-bold border-0 rounded py-2"  
            onClick={submitHandler}>Reset</button>
          </>
        }
      </div>
    </div>
  )
}

export default ResetPasswordConfirm;