import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import person from '../icons/Person.svg'
import alert from '../icons/Alert.svg'




const Header = (props) => {

  const [logoutOPen, setLogoutOpen] = useState(false)
  const navigate = useNavigate()


const logoutHandler = () =>{
  props.setUserInfo(null)
  localStorage.removeItem('access')
  navigate('/')
  setLogoutOpen(false)
}

      return (
        <div className = "navbar-nav bg-primary px-0 px-md-5"
          style={{height:"5rem"}}
        >
          <div className = "container-fluid py-0 py-lg-2 d-flex h-100" >
            {
                <div className = "w-100 h-100">
                  <ul className = "w-100 h-100 navbar-nav me-auto mb-2 mb-lg-0 d-flex flex-row justify-content-end" >
                    <li className = "col-12 h-100 px-0 px-md-3 py-3 position-relative col-lg-4 nav-item d-flex flex-row text-center align-items-center">
                      <a className='col-3' href = "http://cugate.com/our-team.php">
                        <div className = "text-secondary ms-0 ms-md-5">
                          About us
                        </div>
                      </a>
                      <a className='col-3' href='http://cugate.com/impressum.php'>
                        <div className = "text-secondary ms-0 ms-md-4">
                          Impressum
                        </div>
                      </a>
                      <a className='col-4 col-lg-3' href = "http://cugate.com/privacy-policy.php">
                        <div className = "text-secondary ms-0 ms-md-4">
                          Privacy Policy
                        </div>
                      </a>
                      <div className = "position-relative h-100 col-1 ms-3 ms-md-4 d-flex flex-column align-items-center">
                          <img src = {person} 
                            style = {{width:"2rem", height:"2rem", cursor:"pointer"}}
                            onClick={()=>setLogoutOpen(!logoutOPen)} />
                        {
                            logoutOPen && 
                            <div className = "position-absolue mt-2 d-flex align-items-end justify-content-end rounded"
                              role='button'
                              style={{zIndex:1}}>
                              <div className = "bg-white d-flex py-2 px-3 align-items-center justify-content-center rounded" 
                                onClick={()=>{logoutHandler()}}>
                                Logout
                              </div>
                            </div>
                          }
                        </div>
                    </li>
                  </ul>
                </div>
            }
          </div>
        </div>
  );
}

export default Header