import React, { useEffect, useState, useCallback } from 'react';
import ReactPlayer from 'react-player'


const Player = (props) => {

    function toTimeString(seconds) {
        let inTime = (new Date(seconds * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        if(seconds < 3600){
            inTime = inTime.slice(3, 8)
        }
        return (inTime)
      }



    const onReady = () => {
        props.setTimeDisplay("00:00")
        props.setDuration("00:00")
        let d = props.playerRef?.getDuration()
        d && props.setDuration(toTimeString(d))
        props.setPlaying(true)
        if(props.tog === 1){
            props.toggleFullScreen()
            props.setTog(2)
        }
    }

    const handleProgress = (changeState) => { 
        props.setPlayed({...props.played, ...changeState})
        let t = props.playerRef?.getCurrentTime()
        t && props.setTimeDisplay(toTimeString(t)) 
    }


    return (
        <div>
            <ReactPlayer
                ref = {props.setPlayerRef}
                controls
                url={`https://www.youtube.com/watch?v=${props.activeVideo.contentDetails.videoId}?rel=0`}
                onEnded = {props.nextHandler}
                onError = {props.nextHandler}
                // onStart = {startHandler}
                playing = {props.playing}
                onReady = {onReady}
                onProgress = {handleProgress}
                volume = {props.sound}
                muted = {props.muted}
                config={{
                youtube: {
                    playerVars : {
                    controls: 0,
                    showinfo: 0,
                    modestbranding: 1,
                    rel: 0,
                    autoplay:1
                    }
                }
                }}
                width = {"100%"}
                height={props.full ? "100vh" :`${props.windowHeight}vw`}
            />
        </div>
    );
};

export default Player;