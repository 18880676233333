import React, {useState, useRef, useEffect} from 'react'
import screenfull from "screenfull"
import ShareContent from '../ShareContent'
import VideoHeader from './VideoHeader'
import OverLay from './OverLay'
import Player from './Player'




const VideoPlayer = (props) => {

    const [sound, setSound] = useState(0.1)
    const [full, setFull] = useState(false)
    const [muted, setMuted] = useState(false)
    const [played, setPlayed] = useState(0)
    const [windowHeight, setWindowHeight] = useState()
    const [iconSize, setIconSize] = useState()
    const [menuVisible, setMenuVisible] = useState('visible')
    const [display, setDisplay] = useState('inherit')
    const [minimize, setMinimize] = useState(false)
    const [duration, setDuration] = useState()
    const [playerRef, setPlayerRef] = useState()
    const [timeDisplay, setTimeDisplay] = useState("00:00")
    const playerContainerRef = useRef()



    const toggleFullScreen = () => {
        screenfull.toggle(playerContainerRef.current)
        setFull(!full)
        setDisplay("inherit")
        setMinimize(false)
    }



    const prevHandler = () => {
        if (props.playlist && props.playlist.length){
            let index = props.playlist.findIndex(data => data === props.activeVideo);
            if(index > 0){
              props.setActiveVideo(props.playlist[index-1])
              localStorage.setItem('video_index', parseInt(localStorage.getItem('video_index'))-1)
            }
          }
        props.setPlaying(true)
    }

    const nextHandler = () => {
        if (props.playlist && props.playlist.length){
            let index = props.playlist.findIndex(data => data === props.activeVideo);
            if(index < props.playlist.length-1){
              props.setActiveVideo(props.playlist[index+1])
              localStorage.setItem('video_index', parseInt(localStorage.getItem('video_index'))+1)
            } else {
                props.setActiveVideo(props.playlist[0])
                localStorage.setItem('video_index', 1)
            }
          }
        props.setPlaying(true)
    }

    const minimizeHandler = () => {
        setMinimize(!minimize)
        if(minimize){
            setDisplay("inherit")
        } else {
            setDisplay("none")
        }

        if(full){
            screenfull.toggle(playerContainerRef.current)
            setFull(false)
        }
    }


    useEffect(()=>{
        if(props.dimentions.width < 600){
            setIconSize(2)
            setWindowHeight(28.23)
        } else if (props.dimentions.width < 1024){
            setIconSize(1.5)
            setWindowHeight(22.35)
        } else {
            setIconSize(1.042)
            setWindowHeight(16)
        }
    },[props.dimentions.width])




    return(
        <div className = {full ? "" : "w-100 h-100 p-1 rounded-2 d-flex flex-column"} ref = {playerContainerRef}>  
            <div className = "w-100 bg-primary d-flex flex-row justify-content-center align-items-center"
                style = {full ? {visibility: props.playing ? `${menuVisible}`:"visible"}:{visibility:"visible"}}
            >
                <VideoHeader
                    setMenuVisible = {setMenuVisible}
                    full = {full}
                    minimize = {minimize}
                    menuVisible = {menuVisible}
                    iconSize = {iconSize}
                    // fontSize = {fontSize}
                    minimizeHandler = {minimizeHandler}
                    setFull = {setFull}
                    toggleFullScreen = {toggleFullScreen}
                    setTrigger = {props.setTrigger}
                    setActiveVideo = {props.setActiveVideo} 
                    setScrolltoSp = {props.setScrolltoSp}
                    setTog = {props.setTog}
                    tog = {props.tog}
                />
            </div>
            <div style = {{
                    display:`${display}`,
                    // visibility: playing ? `${menuVisible}` : "visible"
                }}>
                <div className = 'w-100 d-flex align-items-center justify-content-center'>
                    <OverLay
                        full = {full}
                        menuVisible = {menuVisible}
                        playing = {props.playing}
                        playerRef = {playerRef}
                        prevHandler = {prevHandler}
                        nextHandler = {nextHandler}
                        dimentions = {props.dimentions}
                        setMenuVisible = {setMenuVisible}
                        played = {played}
                        setPlaying = {props.setPlaying}
                        setPlayed = {setPlayed}
                        setSound = {setSound}
                        setMuted = {setMuted}
                        muted = {muted}
                        duration = {duration}
                        timeDisplay = {timeDisplay}
                        windowHeight = {windowHeight}
                        // fontSize = {fontSize}
                        playlist = {props.playlist}
                        setActiveVideo = {props.setActiveVideo}
                        setTrigger = {props.setTrigger}
                        setScrolltoSp = {props.setScrolltoSp}
                        toggleFullScreen = {toggleFullScreen}
                    />
                    <div className='w-100' style = {{position:"relative", height:"100%"}}>
                        <Player
                            toggleFullScreen = {toggleFullScreen}
                            setPlayerRef = {setPlayerRef}
                            playerRef = {playerRef}
                            full = {full}
                            muted = {muted}
                            sound = {sound}
                            playing = {props.playing}
                            windowHeight = {windowHeight}
                            nextHandler = {nextHandler}
                            played = {played}
                            setPlaying = {props.setPlaying}
                            setPlayed = {setPlayed}
                            setTimeDisplay = {setTimeDisplay}
                            setDuration = {setDuration}
                            activeVideo = {props.activeVideo}
                            setTog = {props.setTog}
                            tog = {props.tog}
                        />
                    </div>
                </div>
            </div>
            {
                !minimize &&
                <>
                    <div className = 'text-primary mt-2 px-1' 
                        style = {{
                            aspectRatio:"16/1",
                            textOverflow:"ellipsis", 
                            overflow:"hidden",  
                        }}>
                        {props.activeVideo.snippet.title}
                    </div>
                    <div className = "d-flex flex-row justify-content-end mb-2 pe-2">
                        <ShareContent dimentions = {props.dimentions}/>
                    </div>
                </>
            }
        </div>
    )
}

export default VideoPlayer

