import React from 'react';

const Description = (props) => {
    return (
        <div className = "bg-transparent d-flex align-items-center justify-content-center pt-5 px-1 px-lg-4">
            <div className = "container-fluid col-11 col-lg-9 col-sm-11 col-md-9 d-flex align-items-center justify-content-center border border-info rounded-4">
                <div className = "text-secondary text-center py-3 col-9 fs-1" style = {{ fontWeight:"350"}}>
                    CUGATE AG is offering a multitude of copyright-free Video and Steaming channels 
                    for shops and businesses. Try our services for free and 
                    <a href = "mailto:playlists@cugate.com" className = "text-secondary text-decoration-none" 
                    style = {{fontWeight:"500", cursor:"pointer"}}> get in touch with us</a> for more details!
                </div>
            </div>
        </div>
    );
};

export default Description;