import React, {useState, useEffect} from 'react'
import YoutubePlaylists from './YoutubePlaylists'
import SpotifyPlaylists from './SpotifyPlaylists'
import { useNavigate } from 'react-router-dom'
import Header from './Header'
import Groups from './Groups'

const Home = (props) => {

    const [group, setGroup] = useState()
    const navigate = useNavigate()

    useEffect(()=>{
        let q = {}
        let groupKeyword
        let kw

        if (props.searchParams.get('g')){
            groupKeyword = props.searchParams.get('g')
            localStorage.setItem('g', groupKeyword)
         } else if (localStorage.getItem('g')){
            groupKeyword = localStorage.getItem('g')
            q["g"] = groupKeyword
        }

        if (props.searchParams.get('kw')){
            kw = props.searchParams.get('kw')
            localStorage.setItem('kw', kw)
        } else if (localStorage.getItem('kw')){
            kw = localStorage.getItem('kw')
            q["kw"] = kw
        } 

        if (props.searchParams.get('pl')){
          localStorage.setItem('pl', props.searchParams.get('pl'))
        } else if (localStorage.getItem('pl')){
          q["pl"] = localStorage.getItem('pl')
        }

        if (props.searchParams.get('sp_id')){
          localStorage.setItem('sp', props.searchParams.get('sp_id'))
        } else if (localStorage.getItem('sp')){
          q["sp"] = localStorage.getItem('sp') 
        }
        props.setSearchParams({ ...Object.fromEntries([...props.searchParams]), ...q})

        let gr = props.groups.find(g => g.keyword === groupKeyword)
        setGroup(gr)

        props.setPlYoutubeKeyword(kw)
    },[])

    if(props.loadUser){
        return <div>Loading...</div>
    } else if(props.userInfo) {
            return(
                <> 
                    <Header
                        dimentions = {props.dimentions}
                        setUserInfo = {props.setUserInfo}
                    />
                    <Groups
                        setSearchParams = {props.setSearchParams}
                        setGroup = {setGroup}
                        groups = {props.groups}
                        dimentions = {props.dimentions}
                        searchParams = {props.searchParams}
                    />
                        
                    <div>
                        { 
                        group &&
                            <YoutubePlaylists 
                                setSearchParams = {props.setSearchParams}
                                searchParams = {props.searchParams}
                                group = {group}
                                dimentions = {props.dimentions}
                                setActiveVideo = {props.setActiveVideo}
                                setVideoTrigger = {props.setVideoTrigger}
                                setActivePlaylist = {props.setActivePlaylist}
                                activeVideo = {props.activeVideo}
                                activePlaylist = {props.activePlaylist}
                                // setFull = {props.setFull}
                                plYoutubeKeyword = {props.plYoutubeKeyword}
                                setPlYoutubeKeyword = {props.setPlYoutubeKeyword}
                                // full = {props.full}
                                setPlaying = {props.setPlaying}
                                setScrolltoSp = {props.setScrolltoSp}
                                setTog = {props.setTog}
                            />
                        }
                    </div>
                    {
                        group &&
                        <SpotifyPlaylists
                            setSearchParams = {props.setSearchParams}
                            searchParams = {props.searchParams}
                            group = {group}
                            dimentions = {props.dimentions}
                            spotifyPlId = {props.spotifyPlId}
                            setSpotifyPlId = {props.setSpotifyPlId}
                            scrollToSp = {props.scrollToSp}
                            setScrolltoSp = {props.setScrolltoSp}
                    />
                    }
                </>
            ) 
        } else {
            navigate('/login')
        }

}

export default Home