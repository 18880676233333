import React, { useEffect, useState } from 'react'
import Default from '../covers/Default.jpg'

const Slide = (props) => {

    return(
        <div id = {props.item.id} className = "">
            <img  src={props.item.cover && props.item.cover} alt = ""
                onError = {({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src=Default
                  }}
                className={props.chosenPlaylist === props.item.id ? 'border border-4 border-primary rounded':'border border-4 border-light rounded'}
                style={
                    {
                        objectFit: 'cover',
                        cursor: 'pointer',
                        aspectRatio:"16/9",
                        width:"100%",
                    }
                }>
            </img>
            <div className = "text-light fw-light pt-2 pt-lg-3" 
                title={props.item.title}
                style = {{  
                    aspectRatio:"16/3",
                    overflow:"hidden",
                    WebkitLineClamp: "3",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                }}>{props.item.title}
            </div>
        </div>
    )
}

export default Slide