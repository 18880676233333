import React from 'react';
import Default from '../covers/Default.jpg'

const SinglePlaylist = (props) => {

    const clickHandler = (i, index) => {
        props.setActivePls(i.keyword); 
        props.setActiveLine(index)
        props.setChosenPlaylist(i.id)
        props.setActive(true)
        props.setSearchParams({...Object.fromEntries([...props.searchParams]), l: index, p: i.keyword})
        localStorage.setItem('l', index)
        localStorage.setItem('pl', i.keyword)
    }

    return (
        <div className='w-100 px-1' onClick={()=>{clickHandler(props.i, props.index)}} >
            <img  src={props.i.cover && props.i.cover } alt = ""
                onError = {({ currentTarget }) => {
                    currentTarget.onerror = null
                    currentTarget.src=Default
                    }}
                className={props.chosenPlaylist === props.i.id ?'border border-4 border-primary rounded w-100' :'border border-4 border-light rounded w-100'}
                style={
                    {
                        objectFit: 'cover',
                        cursor: 'pointer',
                        aspectRatio:"16/9"
                    }
                }>
            </img>
            <div className = "text-light fw-light pt-2 w-100 pe-3" 
                title={props.i.title}
                style = {{
                    aspectRatio:"3.5rem",
                    overflow:"hidden",
                    WebkitLineClamp: "3",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                }}>{props.i.title}
            </div>
        </div>
    );
};

export default SinglePlaylist;