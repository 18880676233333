import React, {useState} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import './AuthStyles.css'
import axios from 'axios'


const Registration = (props) =>{

    const [infoMessage, setInfoMessage] = useState()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobileNumber, setMobileNumber] = useState()
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [confirmMessage, setConfirmMessage] = useState()
    const [checked, setChecked] = useState(false)
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const numberRegex = /^\+\d{6,13}$/i;

    const location = useLocation()

    const config = {
      headers:{
        'Content-type':'application/json',
        'Accept': 'application/json'
      }
    }


    const submitHandler = (e) => {
      e.preventDefault()
      if(!firstName || firstName.length < 2 || !lastName || lastName.length < 2){
        setInfoMessage("Please, fill required fields") 
      }
      else if(!password || password.length < 8){
        setInfoMessage("Please provide password at least 8 symbols")
      }
      else if (!emailRegex.test(email)){
        setInfoMessage("Please, Enter Valid Email")
      } else if (!numberRegex.test(mobileNumber)){
        setInfoMessage("Please, enter valid mobile number with country code")
      }
      else if (password !== confirmPassword){
        setInfoMessage('Passwords do not match')
      } else {
        axios.post(`${process.env.REACT_APP_API_URL}/cuPlaylist/users/checkEmail/`,
          { 'email': email, 'index': 1},
          // config
        ).then(response => {
          axios.post(`${process.env.REACT_APP_API_URL}/cuPlaylist/auth/users/`,
            {'first_name': firstName, 'last_name': lastName, 'email': email, 'mobile_number': mobileNumber, 'gives_permission': checked, 'password': password, 're_password': confirmPassword},
            // config
          )
            .then(response => {
              setConfirmMessage('Activation Link Is sent to your email, Please, Check and confirm')
            })
            .catch(message => {
              setInfoMessage(message.response && message.response.data.password
              ? message.response.data.password
              : "Something Went Wrong Please Try Again")
            })
        }
      ).catch(message =>{
        setInfoMessage(message.response && message.response.data.detail
        ? message.response.data.detail
        : message.message)
      }
    )
      }
    }
  

    const continueWithGoogle = (e) => {
      e.preventDefault()
      axios.get(`${process.env.REACT_APP_API_URL}/cuPlaylist/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_API_URL}`,
        config
      ).then(res =>
        {
          window.location.replace(res.data.authorization_url)
        }
      ).catch(
         console.log('ok')
      )
    }

    if (props.userInfo){
        return <Navigate to = '/home'/>
      } else 
    if(confirmMessage && confirmMessage.length) {
        return(
          <div className = "min-vh-100 d-flex flex-column align-items-center justify-content-center">
           <div className = "text-info">{confirmMessage}</div>
          </div>
        )
      } else {
        return (
          <div className = "container-fluid min-vh-100 d-flex align-items-center"
          style={{background:"linear-gradient(#051932, #07294C)", padding:0, margin:0}}>
            <div className='container d-flex flex-column align-items-center justify-content-center'
              style={{gap:"1.5rem"}}>
              <div className = "text-info fs-4 fw-bold">Registration</div>
                {infoMessage && <div className = "col-10 col-md-8 col-lg-4 text-info text-center">{infoMessage}</div>}
                <div className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded d-flex justify-content-center align-items-center text-white" 
                  style={{background:"#FD5A22"}}
                  role = "button"
                  onClick = {continueWithGoogle}>
                  Continue with google 
                  <span className = "fs-lg" style = {{marginLeft:"0.5vw", fontWeight:"500", fontSize:"1.25rem"}}>G</span>
                </div>
                  <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input"
                    type="text" value = {firstName}  
                    placeholder="First Name" 
                    name = "firstName"
                    onChange = {(e) => {setFirstName(e.target.value); setInfoMessage("")}} />
  
                  <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input"
                    type="text" 
                    value = {lastName} 
                    name = "lastName"
                    placeholder="Last Name" 
                  onChange = {(e) => {setLastName(e.target.value); setInfoMessage("")}} />
  
                  <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input"
                    type="email" 
                    value = {email} 
                    name = "newEmail"
                    placeholder="Email" 
                  onChange = {(e) => {setEmail(e.target.value); setInfoMessage("")}} />

                  <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input"
                  type="tel" 
                  value = {mobileNumber} 
                  name = "phone"
                  placeholder="Phone" 
                  onChange = {(e) => {setMobileNumber(e.target.value); setInfoMessage("")}} />
  
                  <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input"
                    type="password" 
                    value = {password}
                    name = "newPassword"
                    placeholder="Password" 
                  onChange = {(e) => {setPassword(e.target.value); setInfoMessage("")}} />
  
                  <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input"
                    type="password" 
                    value = {confirmPassword} 
                    name = "newPasswordConfirm"
                    placeholder="Confirm Password" 
                  onChange = {(e) => {setConfirmPassword(e.target.value); setInfoMessage("")}} />
                <div className = "col-10 col-md-8 col-lg-4  d-flex flex-row align-items-center" >
                  <input type="checkbox" 
                      style={{accentColor:"#029eb0"}}
                      checked = {checked}
                      onClick={()=>setChecked(!checked)}/>
                      <div className = "text-info ps-2" id = "check_text" 
                      >I agree to get promotional emails from Cugate 
                      about new playlists and content</div>
                </div>
                <button className = "col-10 col-md-8 col-lg-4 text-primary bg-info fw-bold border-0 rounded py-2"
                onClick={submitHandler}>Sign Up</button>
            </div>
          </div>
        )
      }
}

export default Registration;
