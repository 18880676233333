import React from 'react';
import Default from '../covers/Default.jpg'

const SingleSpotify = (props) => {


    const clickHandler = (i, index) => {
        props.setActivePls(i.playlist_id); 
        props.setActiveLine(index)
        props.setChosenPlaylist(i.id)
        props.setActive(true)
        props.setSearchParams({...Object.fromEntries([...props.searchParams]), l: index, p: i.playlist_id})
        localStorage.setItem('s_l', index)
        localStorage.setItem('sp', i.playlist_id)
    }


    return (
        <div className='w-100 px-1' onClick={()=>{clickHandler(props.i, props.index)}} >
            <img  src={props.i.cover ? props.i.cover : "aaaaaa"} alt = "aaaaaa"
                className={ props.chosenPlaylist === props.i.id ? 'border border-4 border-primary rounded w-100':'border border-4 border-light rounded w-100'}
                onError = {({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src=Default
                }}
                style={
                    {
                        objectFit: 'cover',
                        cursor: 'pointer',
                        aspectRatio:"16/16"
                    }
                }>
            </img>
            <div className = "text-light fw-light pt-2 w-100 pe-3"
                title={props.i.title}
                style = {{
                    height:"3.5rem",
                    overflow:"hidden",
                    WebkitLineClamp: "3",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                }}>{props.i.title}
            </div>
        </div>
    );
};

export default SingleSpotify;