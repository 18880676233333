import React, {useState, useEffect} from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios'

function Login(props) {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState()
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const navigate = useNavigate()
    
    const submitHandler = (e) => {
      e.preventDefault()
      if(!emailRegex.test(email)){
        setMessage("Please, Enter Valid Email")
      } else {
        axios.post(`${process.env.REACT_APP_API_URL}/cuPlaylist/auth/jwt/create/`,
        { 'email': email, 'password': password}
      )
        .then(response => {
          localStorage.setItem('access', response.data['access'])
          if (response.data['access']){
            const config = {
                headers:{
                  // 'Content-type':'application/json',
                  'Authorization': `JWT ${localStorage.getItem('access')}`,
                  'Accept': 'application/json'
                 }
              }
  
              axios.get(`${process.env.REACT_APP_API_URL}/cuPlaylist/auth/users/me/`,
                config
              )
                .then(response => {
                  props.setUserInfo(response.data)
                  navigate('/home')
                })
                .catch(message => {
                  setMessage("Something went wrong, please, try again")
                })
          }
        })
        .catch(message => {
          setMessage("Email Or Password Is Not Valid")
        })
      }
    }

    if (props.userInfo){
        return <Navigate to = '/home'/>
      } else {
      return (
        <div className = "container-fluid min-vh-100 d-flex align-items-center"
        style={{background:"linear-gradient(#051932, #07294C)", padding:0, margin:0, height:"100vh"}}>
            <div className='container d-flex flex-column align-items-center justify-content-center'
              style={{gap:"1.5rem"}}>
              {message && <div className = "col-10 col-md-8 col-lg-4 text-info text-center">{message}</div>}
              <button className = "col-10 col-md-8 col-lg-4 bg-secondary fw-bold border-0 rounded py-2" 
                onClick={()=>navigate('/register')}>Sign Up</button>

              <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input" 
                type="email" 
                value = {email} 
                name = "email"
                placeholder="Email" 
                onChange = {(e) => {setEmail(e.target.value); setMessage('')}} />
    
              <input className = "col-10 col-md-8 col-lg-4 p-2 border-0 rounded input" 
                type="password" 
                value = {password} 
                name = "password"
                placeholder="Password" 
              onChange = {(e) => {setPassword(e.target.value); setMessage('')}} 
                  />

              <button className = "col-10 col-md-8 col-lg-4 text-primary bg-info fw-bold border-0 rounded py-2" onClick={submitHandler}
              >Sign In</button>
              <div className = "col-10 col-md-8 col-lg-4 text-center text-info fw-light text-decoration-underline" 
                role = "button"
                onClick={()=>navigate('/reset_password')}
                >Forgot Your Password?</div>
              </div>
        </div>
      )
    }

}

export default Login;
