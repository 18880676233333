import React from 'react';
import Close from '../icons/Close.svg'
import CuViewIcon from '../icons/CuViewIcon.svg'
import FullScreen from '../icons/FullScreen.svg'
import ArroDown from '../icons/Down.svg'
import ArroUp from '../icons/Up.svg'

const VideoHeader = (props) => {

    function exitHandler() {
        if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            props.setFull(false)
        }
    }

    if (document.addEventListener) {
        document.addEventListener('webkitfullscreenchange', exitHandler, false);
        document.addEventListener('mozfullscreenchange', exitHandler, false);
        document.addEventListener('fullscreenchange', exitHandler, false);
        document.addEventListener('MSFullscreenChange', exitHandler, false);
    }



    
    return (
        <div className = {props.full ? "bg-primary py-2 d-flex flex-row justify-content-between align-items-center" : "bg-primary py-1 d-flex flex-row justify-content-between align-items-center"}
            onMouseEnter = {()=>props.setMenuVisible("visible")}               
            style = {{ width:"100%", height:"2.6vw", position: props.full ? "absolute":"", top:"0", zIndex:"1000"}}
            >
            <div className='d-flex flex-row align-items-center' style = {{width:"80%"}}>
                <img className = "mx-3" src = {CuViewIcon} style = {{width:`1.354vw`}}/>
                <div className = "text-white pt-1 fs-1">CuView</div>
            </div>
            <div className = {props.full? "h-100 d-flex flex-row justify-content-between":"h-100 d-flex flex-row w-25 justify-content-between px-2"}>
                <div className = "h-100 d-flex align-items-center justify-content-center" 
                    onClick = {(e)=>{e.stopPropagation(); props.minimizeHandler()}}
                    onMouseDown = {(e)=>{e.stopPropagation(); props.minimizeHandler()}}
                    style = {{width:"33%"}}
                    >
                    
                    <img 
                        className={props.full ? 'me-3 p-2' : ''} 
                        title = {props.minimize ? "Open Window":"Minimize"}
                        src = {props.minimize ? ArroUp : ArroDown} 
                        style = {{
                            width: props.full ? "2.2vw" :`${props.iconSize}vw`, 
                            cursor:"pointer"
                        }} 
                        />
                </div>
                <div className = "h-100 d-flex align-items-center justify-content-center" 
                    onClick = {(e)=>{e.stopPropagation(); props.toggleFullScreen();}}
                    onMouseDown = {(e)=>{e.stopPropagation(); props.toggleFullScreen();}}
                    style = {{width:"33%"}}>
                    <img className={props.full ? 'me-3 p-2' : ''} 
                        title = {props.full ? "Exit Full Screen" : "Full Screen"} 
                        src = {FullScreen} 
                        style = {{
                            width: props.full ? "2.2vw" :`${props.iconSize}vw`, 
                            cursor:"pointer"
                        }} 
                        />
                </div> 
                <div className = "h-100 d-flex align-items-center justify-content-center" 
                    onClick = {(e)=>{e.stopPropagation(); props.setTrigger(false); props.setActiveVideo(); props.setFull(false); props.setTog(1)}}
                    onMouseDown = {(e)=>{e.stopPropagation(); props.setTrigger(false); props.setActiveVideo(); props.setFull(false); props.setTog(1)}}
                    style = {{width:"33%"}}>
                    <img className={props.full ? 'me-3 p-2' : ''} 
                        title = "Close"
                        src = {Close} 
                        style = {{
                            width: props.full ? "2.2vw" :`${props.iconSize}vw`, 
                            cursor:"pointer"
                        }} 
                        />
                </div>
            </div>
        </div> 
    );
};

export default VideoHeader;