import React from 'react'

const Notification = ({message}) => {
    return (
        <div className = "bg-primary w-100 text-center pt-3 pb-2 border-0 rounded-5 mb-5 text-nowrap">
            <h6 className = "text-info" style = {{fontSize:"1.25rem"}}>{message}</h6>
        </div>
    )
}

export default Notification