import React, {useEffect, useState} from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import SpotifyLine from './SpotifyLine'
import LeftArrow from '../icons/LeftArrow.svg'

const ShowAllSpotify = (props) => {
    const {groupKeyword} = useParams()
    const [playlists, setPlaylists] = useState()
    const [cols, setCols] = useState(4)
    const [pls, setPls] = useState([])
    const [group, setGroup] = useState([])
    const [chosenPlaylist, setChosenPlaylist] = useState()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    let a_l = parseInt(searchParams.get('l'))
    const [activeLine, setActiveLine] = useState(a_l)
    let a_p = searchParams.get('p')
    const [activePls, setActivePls] = useState(a_p)

    useEffect(()=>{
        if (props.groups){
            let gr = props.groups.find(g => g.keyword === groupKeyword)
            setGroup(gr)
        }
    },[])

    useEffect(()=>{
        if(group && Object.keys(group).length){
            axios.get(`${process.env.REACT_APP_API_URL}/cuPlaylist/spotifyPlaylists/${group.id}/${group.index}/`)
            .then(res => {
                setPlaylists(res.data)
            })
            .catch(error => { 
                console.log(error)
            })
        }
    },[group])

    useEffect(()=>{
        if(props.dimentions.width < 600){
            setCols(4)
        } else if (props.dimentions.width < 1024){
            setCols(6)
        } else {
            setCols(8)
        }
    },[props.dimentions.width])

    useEffect(()=>{
        var arrays = [], size = cols;
        if(playlists && playlists.length > cols){
            for (let i = 0; i < playlists.length; i += size)
            arrays.push(playlists.slice(i, i + size));
        } else {                                                                                                                
            arrays.push(playlists)
        }
        setPls(arrays)
    },[playlists, cols])

    return (
        <>
            {
                group && Object.keys(group).length ?
                <div className = "w-100 bg-dark" style = {{minHeight:"100vh"}}>
                <div className = "w-100 d-flex flex-column align-items-center justify-content-center">
                    <div className = "container-fluid py-3 col-12 col-lg-10 col-sm-12 col-md-11 px-1 px-lg-5 d-flex justify-content-start">
                        <div className = "col-4 py-2 d-flex justify-content-start align-items-center">
                            <img className = "ms-2" 
                                src = {LeftArrow} 
                                role="button" alt = "aaa" 
                                style={{height:"3.5rem"}}
                                onClick = {()=>navigate(-1)}/>
                        </div>
                        <div className = "col-4 text-center text-secondary py-4 fs-3" style = {{ fontWeight:"200"}}>
                            {group.title.toUpperCase()}</div> : ""
                    </div>
                        <div className='w-100'>
                            {
                                pls && pls.length ? 
                                pls.map((item, index)=>(
                                    <div key = {index}>
                                        <SpotifyLine
                                            item = {item}
                                            index = {index}
                                            activePls = {activePls}
                                            setActivePls = {setActivePls}
                                            activeLine = {activeLine}
                                            setActiveLine = {setActiveLine}
                                            dimentions = {props.dimentions}
                                            cols = {cols}
                                            chosenPlaylist = {chosenPlaylist}
                                            setChosenPlaylist = {setChosenPlaylist}
                                            searchParams = {searchParams}
                                            setSearchParams = {setSearchParams}
                                        />
                                    </div>
                                )) : ''
                            }
                        </div>
                    </div>
                </div> : ""
            }
        </>
    );
};

export default ShowAllSpotify;